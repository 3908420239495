<template>
  <div class="cms-gallery">
    <component
      v-if="files.length"
      :is="`view-${view}`"
      :files="files"
      v-bind="$attrs"
      @click-file="fileClicked"
    ></component>
    <div v-else>
      <p>No hay archivos</p>
    </div>
  </div>
</template>

<script>
import ViewList from '@/modules/filesystem/components/List/List.vue';
import ViewGrid from '@/modules/filesystem/components/List/Grid.vue';
import ViewGallery from '@/modules/filesystem/components/List/Gallery.vue';

export default {
  name: 'cms-gallery',
  components: { ViewList, ViewGrid, ViewGallery },

  props: {
    files: {
      type: Array,
      required: false,
      default: () => []
    },

    view: {
      type: String,
      required: false,
      default: 'grid',
      validator: item => ['list', 'grid', 'gallery'].indexOf(item) !== -1
    }
  },

  methods: {
    fileClicked(file) {
      window.open(file.url, '_system');
    }
  }
};
</script>