<template>
  <ui-list
    class="files-list"
    selectable
    :items="fileItems"
    @click-item="clickItem"
    icon-size="48"
  ></ui-list>
</template>

<script>
import { UiList } from '@/modules/ui/components';

export default {
  name: 'files-list',
  components: { UiList },

  props: {
    files: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  computed: {
    fileItems() {
      return this.files.map(file => ({
        text: file.title,
        icon: file.preview || `mime:${file.mimetype}`,
        secondary: this.$options.filters.bytes(file.size),
        file
      }));
    }
  },

  methods: {
    isSelected(file) {
      return false;
    },

    clickItem(item) {
      this.$emit('click-file', item.file);
    },

    deleteFile(file) {
      this.$emit('delete-file', file);
    }
  },

  filters: {
    bytes(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return '';
      }
      if (typeof precision === 'undefined') precision = 1;
      var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
      var number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
        ' ' +
        units[number]
      );
    }
  }
};
</script>